export const gcdDocs = {
  name: 'gcd',
  category: 'Arithmetic',
  syntax: [
    'gcd(a, b)',
    'gcd(a, b, c, ...)'
  ],
  description: 'Compute the greatest common divisor.',
  examples: [
    'gcd(8, 12)',
    'gcd(-4, 6)',
    'gcd(25, 15, -10)'
  ],
  seealso: ['lcm', 'xgcd']
}
