export const trueDocs = {
  name: 'true',
  category: 'Constants',
  syntax: [
    'true'
  ],
  description: 'Boolean value true',
  examples: [
    'true'
  ],
  seealso: ['false']
}
