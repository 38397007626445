export const asinhDocs = {
  name: 'asinh',
  category: 'Trigonometry',
  syntax: [
    'asinh(x)'
  ],
  description: 'Calculate the hyperbolic arcsine of a value, defined as `asinh(x) = ln(x + sqrt(x^2 + 1))`.',
  examples: [
    'asinh(0.5)'
  ],
  seealso: [
    'acosh',
    'atanh'
  ]
}
