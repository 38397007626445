export const eigsDocs = {
  name: 'eigs',
  category: 'Matrix',
  syntax: [
    'eigs(x)'
  ],
  description: 'Calculate the eigenvalues and eigenvectors of a real symmetric matrix',
  examples: [
    'eigs([[5, 2.3], [2.3, 1]])'
  ],
  seealso: [
    'inv'
  ]
}
