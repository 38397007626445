export const detDocs = {
  name: 'det',
  category: 'Matrix',
  syntax: [
    'det(x)'
  ],
  description: 'Calculate the determinant of a matrix',
  examples: [
    'det([1, 2; 3, 4])',
    'det([-2, 2, 3; -1, 1, 3; 2, 0, -1])'
  ],
  seealso: [
    'concat', 'diag', 'identity', 'inv', 'ones', 'range', 'size', 'squeeze', 'subset', 'trace', 'transpose', 'zeros'
  ]
}
