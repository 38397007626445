export const cubeDocs = {
  name: 'cube',
  category: 'Arithmetic',
  syntax: [
    'cube(x)'
  ],
  description: 'Compute the cube of a value. The cube of x is x * x * x.',
  examples: [
    'cube(2)',
    '2^3',
    '2 * 2 * 2'
  ],
  seealso: [
    'multiply',
    'square',
    'pow'
  ]
}
