export const combinationsDocs = {
  name: 'combinations',
  category: 'Probability',
  syntax: [
    'combinations(n, k)'
  ],
  description: 'Compute the number of combinations of n items taken k at a time',
  examples: [
    'combinations(7, 5)'
  ],
  seealso: ['combinationsWithRep', 'permutations', 'factorial']
}
