export const leftShiftDocs = {
  name: 'leftShift',
  category: 'Bitwise',
  syntax: [
    'x << y',
    'leftShift(x, y)'
  ],
  description: 'Bitwise left logical shift of a value x by y number of bits.',
  examples: [
    '4 << 1',
    '8 >> 1'
  ],
  seealso: [
    'bitAnd', 'bitNot', 'bitOr', 'bitXor', 'rightArithShift', 'rightLogShift'
  ]
}
