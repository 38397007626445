export const unaryPlusDocs = {
  name: 'unaryPlus',
  category: 'Operators',
  syntax: [
    '+x',
    'unaryPlus(x)'
  ],
  description:
      'Converts booleans and strings to numbers.',
  examples: [
    '+true',
    '+"2"'
  ],
  seealso: [
    'add', 'subtract', 'unaryMinus'
  ]
}
