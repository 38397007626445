export const expDocs = {
  name: 'exp',
  category: 'Arithmetic',
  syntax: [
    'exp(x)'
  ],
  description: 'Calculate the exponent of a value.',
  examples: [
    'exp(1.3)',
    'e ^ 1.3',
    'log(exp(1.3))',
    'x = 2.4',
    '(exp(i*x) == cos(x) + i*sin(x))   # Euler\'s formula'
  ],
  seealso: [
    'expm',
    'expm1',
    'pow',
    'log'
  ]
}
