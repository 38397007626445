export const cloneDocs = {
  name: 'clone',
  category: 'Utils',
  syntax: [
    'clone(x)'
  ],
  description: 'Clone a variable. Creates a copy of primitive variables,and a deep copy of matrices',
  examples: [
    'clone(3.5)',
    'clone(2 - 4i)',
    'clone(45 deg)',
    'clone([1, 2; 3, 4])',
    'clone("hello world")'
  ],
  seealso: []
}
