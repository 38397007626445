export const eDocs = {
  name: 'e',
  category: 'Constants',
  syntax: [
    'e'
  ],
  description: 'Euler\'s number, the base of the natural logarithm. Approximately equal to 2.71828',
  examples: [
    'e',
    'e ^ 2',
    'exp(2)',
    'log(e)'
  ],
  seealso: ['exp']
}
