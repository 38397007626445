export const coshDocs = {
  name: 'cosh',
  category: 'Trigonometry',
  syntax: [
    'cosh(x)'
  ],
  description: 'Compute the hyperbolic cosine of x in radians.',
  examples: [
    'cosh(0.5)'
  ],
  seealso: [
    'sinh',
    'tanh',
    'coth'
  ]
}
