export const asecDocs = {
  name: 'asec',
  category: 'Trigonometry',
  syntax: [
    'asec(x)'
  ],
  description: 'Calculate the inverse secant of a value.',
  examples: [
    'asec(0.5)',
    'asec(sec(0.5))',
    'asec(2)'
  ],
  seealso: [
    'acos',
    'acot',
    'acsc'
  ]
}
