export const NaNDocs = {
  name: 'NaN',
  category: 'Constants',
  syntax: [
    'NaN'
  ],
  description: 'Not a number',
  examples: [
    'NaN',
    '0 / 0'
  ],
  seealso: []
}
