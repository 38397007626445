export const atan2Docs = {
  name: 'atan2',
  category: 'Trigonometry',
  syntax: [
    'atan2(y, x)'
  ],
  description:
      'Computes the principal value of the arc tangent of y/x in radians.',
  examples: [
    'atan2(2, 2) / pi',
    'angle = 60 deg in rad',
    'x = cos(angle)',
    'y = sin(angle)',
    'atan2(y, x)'
  ],
  seealso: [
    'sin',
    'cos',
    'tan'
  ]
}
