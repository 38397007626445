export const hexDocs = {
  name: 'hex',
  category: 'Utils',
  syntax: [
    'hex(value)'
  ],
  description: 'Format a number as hexadecimal',
  examples: [
    'hex(240)'
  ],
  seealso: ['bin', 'oct']
}
