export const falseDocs = {
  name: 'false',
  category: 'Constants',
  syntax: [
    'false'
  ],
  description: 'Boolean value false',
  examples: [
    'false'
  ],
  seealso: ['true']
}
