/**
 * Create a syntax error with the message:
 *     'Wrong number of arguments in function <fn> (<count> provided, <min>-<max> expected)'
 * @param {string} fn     Function name
 * @param {number} count  Actual argument count
 * @param {number} min    Minimum required argument count
 * @param {number} [max]  Maximum required argument count
 * @extends Error
 */
export function ArgumentsError (fn, count, min, max) {
  if (!(this instanceof ArgumentsError)) {
    throw new SyntaxError('Constructor must be called with the new operator')
  }

  this.fn = fn
  this.count = count
  this.min = min
  this.max = max

  this.message = 'Wrong number of arguments in function ' + fn +
      ' (' + count + ' provided, ' +
      min + ((max !== undefined && max !== null) ? ('-' + max) : '') + ' expected)'

  this.stack = (new Error()).stack
}

ArgumentsError.prototype = new Error()
ArgumentsError.prototype.constructor = Error
ArgumentsError.prototype.name = 'ArgumentsError'
ArgumentsError.prototype.isArgumentsError = true
