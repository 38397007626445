export const typeOfDocs = {
  name: 'typeOf',
  category: 'Utils',
  syntax: [
    'typeOf(x)'
  ],
  description: 'Get the type of a variable.',
  examples: [
    'typeOf(3.5)',
    'typeOf(2 - 4i)',
    'typeOf(45 deg)',
    'typeOf("hello world")'
  ],
  seealso: ['getMatrixDataType']
}
