export const formatDocs = {
  name: 'format',
  category: 'Utils',
  syntax: [
    'format(value)',
    'format(value, precision)'
  ],
  description: 'Format a value of any type as string.',
  examples: [
    'format(2.3)',
    'format(3 - 4i)',
    'format([])',
    'format(pi, 3)'
  ],
  seealso: ['print']
}
