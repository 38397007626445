export const divideDocs = {
  name: 'divide',
  category: 'Operators',
  syntax: [
    'x / y',
    'divide(x, y)'
  ],
  description: 'Divide two values.',
  examples: [
    'a = 2 / 3',
    'a * 3',
    '4.5 / 2',
    '3 + 4 / 2',
    '(3 + 4) / 2',
    '18 km / 4.5'
  ],
  seealso: [
    'multiply'
  ]
}
