export const catalanDocs = {
  name: 'catalan',
  category: 'Combinatorics',
  syntax: [
    'catalan(n)'
  ],
  description: 'The Catalan Numbers enumerate combinatorial structures of many different types. catalan only takes integer arguments. The following condition must be enforced: n >= 0.',
  examples: [
    'catalan(3)',
    'catalan(8)'
  ],
  seealso: ['bellNumbers']
}
