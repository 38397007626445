export const sinDocs = {
  name: 'sin',
  category: 'Trigonometry',
  syntax: [
    'sin(x)'
  ],
  description: 'Compute the sine of x in radians.',
  examples: [
    'sin(2)',
    'sin(pi / 4) ^ 2',
    'sin(90 deg)',
    'sin(30 deg)',
    'sin(0.2)^2 + cos(0.2)^2'
  ],
  seealso: [
    'asin',
    'cos',
    'tan'
  ]
}
