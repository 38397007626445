export const sinhDocs = {
  name: 'sinh',
  category: 'Trigonometry',
  syntax: [
    'sinh(x)'
  ],
  description: 'Compute the hyperbolic sine of x in radians.',
  examples: [
    'sinh(0.5)'
  ],
  seealso: [
    'cosh',
    'tanh'
  ]
}
