export const expm1Docs = {
  name: 'expm1',
  category: 'Arithmetic',
  syntax: [
    'expm1(x)'
  ],
  description: 'Calculate the value of subtracting 1 from the exponential value.',
  examples: [
    'expm1(2)',
    'pow(e, 2) - 1',
    'log(expm1(2) + 1)'
  ],
  seealso: [
    'exp',
    'pow',
    'log'
  ]
}
