export const LOG2EDocs = {
  name: 'LOG2E',
  category: 'Constants',
  syntax: [
    'LOG2E'
  ],
  description: 'Returns the base-2 logarithm of E, approximately equal to 1.442',
  examples: [
    'LOG2E',
    'log(e, 2)'
  ],
  seealso: []
}
