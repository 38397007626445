export const LOG10EDocs = {
  name: 'LOG10E',
  category: 'Constants',
  syntax: [
    'LOG10E'
  ],
  description: 'Returns the base-10 logarithm of E, approximately equal to 0.434',
  examples: [
    'LOG10E',
    'log(e, 10)'
  ],
  seealso: []
}
