export const acscDocs = {
  name: 'acsc',
  category: 'Trigonometry',
  syntax: [
    'acsc(x)'
  ],
  description: 'Calculate the inverse cotangent of a value.',
  examples: [
    'acsc(2)',
    'acsc(csc(0.5))',
    'acsc(0.5)'
  ],
  seealso: [
    'csc',
    'asin',
    'asec'
  ]
}
