export const isIntegerDocs = {
  name: 'isInteger',
  category: 'Utils',
  syntax: [
    'isInteger(x)'
  ],
  description: 'Test whether a value is an integer number.',
  examples: [
    'isInteger(2)',
    'isInteger(3.5)',
    'isInteger([3, 0.5, -2])'
  ],
  seealso: ['isNegative', 'isNumeric', 'isPositive', 'isZero']
}
