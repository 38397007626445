export const cscDocs = {
  name: 'csc',
  category: 'Trigonometry',
  syntax: [
    'csc(x)'
  ],
  description: 'Compute the cosecant of x in radians. Defined as 1/sin(x)',
  examples: [
    'csc(2)',
    '1 / sin(2)'
  ],
  seealso: [
    'sec',
    'cot',
    'sin'
  ]
}
