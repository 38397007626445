export const factorialDocs = {
  name: 'factorial',
  category: 'Probability',
  syntax: [
    'n!',
    'factorial(n)'
  ],
  description: 'Compute the factorial of a value',
  examples: [
    '5!',
    '5 * 4 * 3 * 2 * 1',
    '3!'
  ],
  seealso: ['combinations', 'combinationsWithRep', 'permutations', 'gamma']
}
