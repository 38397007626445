export const tanhDocs = {
  name: 'tanh',
  category: 'Trigonometry',
  syntax: [
    'tanh(x)'
  ],
  description: 'Compute the hyperbolic tangent of x in radians.',
  examples: [
    'tanh(0.5)',
    'sinh(0.5) / cosh(0.5)'
  ],
  seealso: [
    'sinh',
    'cosh'
  ]
}
