export const asinDocs = {
  name: 'asin',
  category: 'Trigonometry',
  syntax: [
    'asin(x)'
  ],
  description: 'Compute the inverse sine of a value in radians.',
  examples: [
    'asin(0.5)',
    'asin(sin(0.5))'
  ],
  seealso: [
    'sin',
    'acos',
    'atan'
  ]
}
