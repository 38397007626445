export const erfDocs = {
  name: 'erf',
  category: 'Special',
  syntax: [
    'erf(x)'
  ],
  description: 'Compute the erf function of a value using a rational Chebyshev approximations for different intervals of x',
  examples: [
    'erf(0.2)',
    'erf(-0.5)',
    'erf(4)'
  ],
  seealso: []
}
