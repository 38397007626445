export const LN2Docs = {
  name: 'LN2',
  category: 'Constants',
  syntax: [
    'LN2'
  ],
  description: 'Returns the natural logarithm of 2, approximately equal to 0.693',
  examples: [
    'LN2',
    'log(2)'
  ],
  seealso: []
}
