export const LN10Docs = {
  name: 'LN10',
  category: 'Constants',
  syntax: [
    'LN10'
  ],
  description: 'Returns the natural logarithm of 10, approximately equal to 2.302',
  examples: [
    'LN10',
    'log(10)'
  ],
  seealso: []
}
