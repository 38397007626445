export const octDocs = {
  name: 'oct',
  category: 'Utils',
  syntax: [
    'oct(value)'
  ],
  description: 'Format a number as octal',
  examples: [
    'oct(56)'
  ],
  seealso: ['bin', 'hex']
}
