export const multiplyDocs = {
  name: 'multiply',
  category: 'Operators',
  syntax: [
    'x * y',
    'multiply(x, y)'
  ],
  description: 'multiply two values.',
  examples: [
    'a = 2.1 * 3.4',
    'a / 3.4',
    '2 * 3 + 4',
    '2 * (3 + 4)',
    '3 * 2.1 km'
  ],
  seealso: [
    'divide'
  ]
}
