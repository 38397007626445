export const modDocs = {
  name: 'mod',
  category: 'Operators',
  syntax: [
    'x % y',
    'x mod y',
    'mod(x, y)'
  ],
  description:
      'Calculates the modulus, the remainder of an integer division.',
  examples: [
    '7 % 3',
    '11 % 2',
    '10 mod 4',
    'isOdd(x) = x % 2',
    'isOdd(2)',
    'isOdd(3)'
  ],
  seealso: ['divide']
}
