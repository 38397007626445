export const cosDocs = {
  name: 'cos',
  category: 'Trigonometry',
  syntax: [
    'cos(x)'
  ],
  description: 'Compute the cosine of x in radians.',
  examples: [
    'cos(2)',
    'cos(pi / 4) ^ 2',
    'cos(180 deg)',
    'cos(60 deg)',
    'sin(0.2)^2 + cos(0.2)^2'
  ],
  seealso: [
    'acos',
    'sin',
    'tan'
  ]
}
