export const lcmDocs = {
  name: 'lcm',
  category: 'Arithmetic',
  syntax: [
    'lcm(x, y)'
  ],
  description: 'Compute the least common multiple.',
  examples: [
    'lcm(4, 6)',
    'lcm(6, 21)',
    'lcm(6, 21, 5)'
  ],
  seealso: ['gcd']
}
