export const log1pDocs = {
  name: 'log1p',
  category: 'Arithmetic',
  syntax: [
    'log1p(x)',
    'log1p(x, base)'
  ],
  description: 'Calculate the logarithm of a `value+1`',
  examples: [
    'log1p(2.5)',
    'exp(log1p(1.4))',
    'pow(10, 4)',
    'log1p(9999, 10)',
    'log1p(9999) / log(10)'
  ],
  seealso: [
    'exp',
    'log',
    'log2',
    'log10'
  ]
}
