export const nthRootsDocs = {
  name: 'nthRoots',
  category: 'Arithmetic',
  syntax: [
    'nthRoots(A)',
    'nthRoots(A, root)'
  ],
  description: ('' +
      'Calculate the nth roots of a value. ' +
      'An nth root of a positive real number A, ' +
      'is a positive real solution of the equation "x^root = A". ' +
      'This function returns an array of complex values.'
  ),
  examples: [
    'nthRoots(1)',
    'nthRoots(1, 3)'
  ],
  seealso: [
    'sqrt',
    'pow',
    'nthRoot'
  ]
}
