export const cotDocs = {
  name: 'cot',
  category: 'Trigonometry',
  syntax: [
    'cot(x)'
  ],
  description: 'Compute the cotangent of x in radians. Defined as 1/tan(x)',
  examples: [
    'cot(2)',
    '1 / tan(2)'
  ],
  seealso: [
    'sec',
    'csc',
    'tan'
  ]
}
