export const squareDocs = {
  name: 'square',
  category: 'Arithmetic',
  syntax: [
    'square(x)'
  ],
  description:
      'Compute the square of a value. The square of x is x * x.',
  examples: [
    'square(3)',
    'sqrt(9)',
    '3^2',
    '3 * 3'
  ],
  seealso: [
    'multiply',
    'pow',
    'sqrt',
    'cube'
  ]
}
