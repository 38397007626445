export const conjDocs = {
  name: 'conj',
  category: 'Complex',
  syntax: [
    'conj(x)'
  ],
  description:
      'Compute the complex conjugate of a complex value. If x = a+bi, the complex conjugate is a-bi.',
  examples: [
    'conj(2 + 3i)',
    'conj(2 - 3i)',
    'conj(-5.2i)'
  ],
  seealso: [
    're',
    'im',
    'abs',
    'arg'
  ]
}
