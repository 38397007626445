export const rightArithShiftDocs = {
  name: 'rightArithShift',
  category: 'Bitwise',
  syntax: [
    'x >> y',
    'rightArithShift(x, y)'
  ],
  description: 'Bitwise right arithmetic shift of a value x by y number of bits.',
  examples: [
    '8 >> 1',
    '4 << 1',
    '-12 >> 2'
  ],
  seealso: [
    'bitAnd', 'bitNot', 'bitOr', 'bitXor', 'leftShift', 'rightLogShift'
  ]
}
