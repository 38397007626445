export const helpDocs = {
  name: 'help',
  category: 'Expression',
  syntax: [
    'help(object)',
    'help(string)'
  ],
  description: 'Display documentation on a function or data type.',
  examples: [
    'help(sqrt)',
    'help("complex")'
  ],
  seealso: []
}
