export const subtractDocs = {
  name: 'subtract',
  category: 'Operators',
  syntax: [
    'x - y',
    'subtract(x, y)'
  ],
  description: 'subtract two values.',
  examples: [
    'a = 5.3 - 2',
    'a + 2',
    '2/3 - 1/6',
    '2 * 3 - 3',
    '2.1 km - 500m'
  ],
  seealso: [
    'add'
  ]
}
