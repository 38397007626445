export const nullDocs = {
  name: 'null',
  category: 'Constants',
  syntax: [
    'null'
  ],
  description: 'Value null',
  examples: [
    'null'
  ],
  seealso: ['true', 'false']
}
