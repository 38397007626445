export const combinationsWithRepDocs = {
  name: 'combinationsWithRep',
  category: 'Probability',
  syntax: [
    'combinationsWithRep(n, k)'
  ],
  description: 'Compute the number of combinations of n items taken k at a time with replacements.',
  examples: [
    'combinationsWithRep(7, 5)'
  ],
  seealso: ['combinations', 'permutations', 'factorial']
}
