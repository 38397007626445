export const addDocs = {
  name: 'add',
  category: 'Operators',
  syntax: [
    'x + y',
    'add(x, y)'
  ],
  description: 'Add two values.',
  examples: [
    'a = 2.1 + 3.6',
    'a - 3.6',
    '3 + 2i',
    '3 cm + 2 inch',
    '"2.3" + "4"'
  ],
  seealso: [
    'subtract'
  ]
}
