export const SQRT12Docs = {
  name: 'SQRT1_2',
  category: 'Constants',
  syntax: [
    'SQRT1_2'
  ],
  description: 'Returns the square root of 1/2, approximately equal to 0.707',
  examples: [
    'SQRT1_2',
    'sqrt(1/2)'
  ],
  seealso: []
}
